<template>
  <u-dashboard-panel-content class="p-0 pb-14">
    <div
      v-if="header"
      class="sticky top-0 z-40 grid items-center gap-2 bg-white/80 px-4 backdrop-blur-sm dark:bg-gray-900/80"
      :class="{
        'pb-2 pt-4': lgAndSmaller,
        'lg:h-[--header-height] lg:min-h-[--header-height]': $slots.header,
      }"
    >
      <u-button
        color="gray"
        variant="ghost"
        leading-icon="i-heroicons-chevron-double-right"
        class="w-fit lg:hidden"
        @click="app.isSidebarOpen = !app.isSidebarOpen"
      />
      <slot name="header" />
    </div>
    <div class="flex flex-col gap-4" :class="{ 'px-4': padding }">
      <slot />
    </div>
  </u-dashboard-panel-content>
</template>

<script lang="ts" setup>
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

withDefaults(
  defineProps<{
    padding?: boolean
    header?: boolean
  }>(),
  {
    padding: true,
    header: true,
  },
)

const app = useApp()
const breakpoints = useBreakpoints(breakpointsTailwind)
const lgAndSmaller = breakpoints.smallerOrEqual('lg')
</script>
